import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Bienvenue sur le site de Net Masters Tennis Hub
			</title>
			<meta name={"description"} content={"Libérez votre potentiel - Faites l'expérience du spectre complet de l'excellence en tennis\n"} />
			<meta property={"og:title"} content={"Bienvenue sur le site de Net Masters Tennis Hub"} />
			<meta property={"og:description"} content={"Libérez votre potentiel - Faites l'expérience du spectre complet de l'excellence en tennis"} />
			<meta property={"og:image"} content={"https://alpineadventuresfr.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://alpineadventuresfr.com/images/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://alpineadventuresfr.com/images/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://alpineadventuresfr.com/images/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline2"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Points forts des services
				</Text>
				<Text font="--lead" color="--darkL2" text-align="center" md-width="100%">
					Net Masters Tennis Hub se consacre à la fourniture d'une gamme de services conçus pour élever votre jeu de tennis et enrichir votre expérience globale. Des programmes d'entraînement complets aux installations de terrain de première qualité, nous sommes votre destination tout-en-un pour l'excellence du tennis.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://alpineadventuresfr.com/images/3.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Programmes d'entraînement
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Développement junior : Conçu pour les jeunes enthousiastes, il met l'accent sur les principes fondamentaux, la technique et le plaisir du jeu.
						<br />
						<br />
						- Stages pour adultes : Que vous soyez débutant ou que vous cherchiez à affiner vos compétences, nos programmes pour adultes s'adressent à tous les niveaux avec un mélange d'entraînement technique, tactique et physique.
						<br />
						<br />
						- Entraînement privé : séances individuelles avec nos entraîneurs experts, adaptées à vos objectifs personnels et à votre emploi du temps pour une amélioration accélérée.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Terrains
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Courts couverts : Jouez toute l'année sur nos courts intérieurs de haute qualité, pour que votre jeu ne soit jamais interrompu par les conditions météorologiques.
						<br />
						<br />
						- Courts extérieurs : Vivez l'expérience de jouer à ciel ouvert sur nos courts extérieurs bien entretenus, conçus pour les matchs de compétition et les jeux décontractés.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Compétition
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Ligues et tournois : Participez à des compétitions amicales, mettez vos compétences à l'épreuve et rencontrez d'autres passionnés de tennis grâce à nos ligues et tournois organisés.
						<br />
						<br />
						- Séances de match play : Les sessions de match play programmées régulièrement offrent aux membres la possibilité de s'entraîner à jouer en compétition dans un environnement favorable.
					</Text>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Santé et condition physique
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						- Programmes de mise en condition : Séances de remise en forme spécifiques au tennis conçues pour améliorer votre force, votre agilité et votre endurance sur le terrain.
						<br />
						<br />
						- Récupération et bien-être : Accès à des outils de rétablissement et à des conseils de bien-être pour vous permettre de rester au sommet de votre forme, physiquement et mentalement.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section background="--color-primary" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h2" margin="12px 0" font="--headline2">
							Communauté et événements
						</Text>
						<Text margin="12px 0" font="--lead" md-font="--headline3">
							- Rencontres sociales : Des événements réguliers qui rassemblent notre communauté de joueurs de tennis, parfaits pour se faire de nouveaux amis et nouer des liens avec d'autres joueurs.
							<br />
							<br />
							- Cliniques et ateliers spéciaux : Cliniques et ateliers occasionnels avec des coachs invités, offrant des perspectives uniques et des opportunités de formation.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://alpineadventuresfr.com/images/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://alpineadventuresfr.com/images/5.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://alpineadventuresfr.com/images/6.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					S'engager avec Net Masters
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Pour de plus amples informations ou pour faire partie de notre communauté de tennis dynamique, nous vous invitons à nous contacter. Notre équipe est impatiente de vous aider à construire votre parcours de tennis chez Net Masters.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://alpineadventuresfr.com/images/7.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});